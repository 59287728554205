<template>
  <div>
    <br />
    <p align="center" style="font-size: 30px">Q&A</p>
    <br />
    <div>
      <div class="two-wrapper">
        <div class="container" style="padding-top: 10px; padding-bottom: 5px">
          <div class="row">
            <div class="col-sx-12">
              <div
                class="card"
                style="card
                border-style: solid solid solid solid;
                border-width: 4px 1px 2px 1px;
                padding-top: 100px;
                padding-bottom: 50px;
              "
              >
                <h1 style="text-align: center">
                  <i
                    class="fa fa-question"
                    aria-hidden="true"
                    style="font-size: 80px; color: #ffcc00"
                  ></i>
                </h1>
                <h3 style="text-align: center; font-size: 20px">
                  คำถามที่พบบ่อย
                </h3>
                <p style="height: 50px; text-align: center">
                  รายการคำถาม และ คำตอบ ที่พบบ่อย
                </p>
                <div class="card-body">
                  <div v-for="(item, i) in data" :key="i">
                    <!-- loop for เพื่อเเสดงผลข้อมูล มาแสดงผลข้อมูล Array โดยใช้ v-for syntax คือ v-for="item in items" 
                    item คือค่า object หรือ  number 
                    data คือค่า Array ของข้อมูลที่เราต้องการ-->
                    <p style="padding-top: 20px">
                      <b-button
                        variant="info"
                        block
                        v-b-toggle="'collapse-1' + i"
                        size="lg"
                        style="background-color: #17a2b8"
                        ><h5 class="text">Q : {{ item.question }}</h5></b-button
                      >
                    </p>
                    <b-collapse size="lg" :id="'collapse-1' + i" class="mt-2">
                      <p>Answer: {{ item.answer }}</p>
                    </b-collapse>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
export default {
  name: "QuestionList",

  data() {
    return {
      baseURL: "https://bo.thundersoftware.co.th/api/qa",
      data: "null",
    };
  },
  methods: {
    show() {
      this.show.data;
    },
    getListQA() {
      Axios.get(this.baseURL).then((response) => {
        //response
        if (response.data.status == 0) {
          this.data = response.data.datas;
        }

        console.log(response);
      });
    },
  },

  created() {
    this.getListQA();
    console.log(this.baseURL);
  },
};
</script>

<style scoped>
.text {
  text-align: left;
  color: aliceblue;
}
.collapse {
  padding-top: 10px;
  padding-left: 50px;
}
</style>
