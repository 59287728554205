<template>
  <div>
    <Header-Main v-if="$route.path != '/login'"></Header-Main>
    <Apply-Work></Apply-Work>
    <router-view />
    <FooTer v-if="$route.path != '/login'"></FooTer>
  </div>
</template>
<script>
import HeaderMain from "./views/HeaderMain.vue";
import FooTer from "./components/FooTer.vue";
import ApplyWork from "@/components/ApplyWork.vue";
export default {
  name: "app",
  data() {
    return {
      scY: 0,
      // is_login: this.$route.path == "/login",
    };
  },
  computed: {
    // is_login() {
    //   return this.$route.path == "/login";
    // },
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },

  components: { HeaderMain, FooTer, ApplyWork },

  // created() {
  //   this.header = this.$route.path == "/login";
  // },
};
</script>


<style>
@import "./assets/demo.css";
</style>
