import Vue from 'vue'
import VueRouter from 'vue-router'
import MainContent from '../views/MainContent.vue'
import WriteProgram from '../components/Service/WriteProgram.vue'
import ConSult from '../components/Service/ConSult.vue'
import LearNing from '../components/Service/LearNing.vue'
import QueryInfo from '../components/QueryInfo.vue'
import AboutUs from '../components/AboutUs.vue'
import FeaTures from'../components/FeaTures.vue'
import QuestionList from '../components/QuestionList.vue'
import AdminLogin from '../views/AdminLogin.vue'

 

Vue.use(VueRouter);

// function guardMyroute(to, from, next)
// {
//  var isAuthenticated= false;
// if(localStorage.getItem('LoggedUser'))
//   isAuthenticated = true;
//  else
//   isAuthenticated= false;
//  if(isAuthenticated) 
//  {
//   next(); 
//  } 
//  else
//  {
//   next('/login'); 
//  }
// }

const routes = [
  {
    path: '/login',
    name: 'AdminLogin',
    component: AdminLogin,
  },
 
  {
    path: '/',
    name: 'MainContent',
    component: MainContent,
 
  },
  {
    path: '/AboutUs',
    name: 'AboutUs',
    component: AboutUs,
  },
  {
    path:'/WriteProgram',
    name:'WriteProgram',
    component : WriteProgram,
  },
  {
    path:'/FeaTures',
    name:'FeaTures',
    component : FeaTures,
  },

  {
    path:'/ConSult',
    name:'ConSult',
    component : ConSult,
  },
  {
    path:'/Learning',
    name:'Learning',
    component : LearNing,
  },
  {
    path:'/QueryInfo',
    name:'QueryInfo',
    component : QueryInfo,
  },
  {
   
    path:'/Question',
    name:'QuestionList',
    component : QuestionList,
  },


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
