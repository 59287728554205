<template>
  <div>
    <br />
    <p align="center" style="font-size: 20px">บริการทั้งหมดของเรา</p>
    <br />
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-18 col-sm-36">
          <div class="single-service-item">
            <span class="top-border"></span>
            <span class="right-border"></span>
            <span class="bottom-border"></span>

            <div class="service-left-bg"></div>
            <div class="service-icon">
              <img
                class="icon center-block"
                src="images/icons/website.png"
                alt=""
              />
            </div>
            <div class="service-text">
              <h4>
                <router-link to="/WriteProgram"
                  >เขียนโปรแกรม /Web application</router-link
                >
                <!-- <a href="WriteProgram">เ</a> -->
              </h4>
              <p>
                รับทำเว็บไซต์ ออกแบบทันสมัย แสดงผลผ่านมือถือได้
                ลูกค้าสามารถอัพเดทเว็บไซต์ได้เอง
              </p>
              <p></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br /><br />

    <br /><br />
    <div class="two-wrapper">
      <section class="no-padding-top">
        <div class="container-fluid">
          <h2 align="center" style="font-size: 40px; color: #0066cc">
            5 ขั้นตอนง่ายๆ กับการทำงานของเรา
          </h2>
          <br /><br /><br />
          <div class="row">
            <div
              class="
                services-column
                col-lg-5 col-lg-offset-1 col-md-10 col-md-offset-1 col-xs-12
              "
            >
              <!--Services Block Four-->
              <div class="services-block-four">
                <div class="inner-box">
                  <div class="icon-box">
                    <span class="fa fa-phone"></span>
                  </div>
                  <h3>รับข้อมูลเบื้องต้น</h3>
                  <div class="text">
                    โทร : 098-272-2356 (คุณแมน) <br />
                    E-Mail: mansuang@thundersoftware.co.th
                  </div>
                </div>
              </div>

              <!--Services Block Four-->
              <div class="services-block-four">
                <div class="inner-box">
                  <div class="icon-box">
                    <span class="fa fa-balance-scale"></span>
                  </div>
                  <h3>ประเมินราคา</h3>
                  <div class="text">
                    ทีมงานจะทำการประเมินระยะเวลา <br />และงบประมานเบื้องต้น
                    (ส่งเอกสารเสนอราคา)
                  </div>
                </div>
              </div>

              <!--Services Block Four-->
              <div class="services-block-four">
                <div class="inner-box">
                  <div class="icon-box">
                    <span class="fa fa-calendar"></span>
                  </div>
                  <h3>นัดพบและเริ่มงาน</h3>
                  <div class="text">
                    ท่านสามารถแจ้งนัดพบ <br />
                    เพื่อเก็บข้อมูลเพิ่มเติมและ พร้อมเริ่มงานได้ในทันที
                  </div>
                </div>
              </div>

              <!--Services Block Four-->
              <div class="services-block-four">
                <div class="inner-box">
                  <div class="icon-box">
                    <span class="fa fa-check-square-o"></span>
                  </div>
                  <h3>ลูกค้าทดสอบระบบ</h3>
                  <div class="text">
                    ทีมงานให้ลูกค้าทดสอบระบบ ก่อนใช้งานจริง
                  </div>
                </div>
              </div>

              <!--Services Block Four-->
              <div class="services-block-four">
                <div class="inner-box">
                  <div class="icon-box">
                    <span class="fa fa-heart-o"></span>
                  </div>
                  <h3>ปิดงาน</h3>
                  <div class="text">
                    ทีมงานจะติดตั้งงานจริง พร้อมส่งมอบผลงาน
                  </div>
                </div>
              </div>
            </div>
            <div class="service-img-wrapper col-lg-6 col-md-12 col-sm-12">
              <div class="image-box">
                <img
                  src="../../assets/images/de-software1.png"
                  class="img-responsive"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <FooTer></FooTer>
  </div>
</template>
<script>
export default {
  name: "WriteProgram",
};
</script>
<style scoped>
.center-block {
  margin-left: auto;
  margin-right: auto;
}
</style>
