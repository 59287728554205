<template>
  <div>
    <br />
    <p align="center" style="font-size: 20px">บริการทั้งหมดของเรา</p>
    <br />
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-18 col-sm-36">
          <div class="single-service-item">
            <span class="top-border"></span>
            <span class="right-border"></span>
            <span class="bottom-border"></span>

            <div class="service-left-bg"></div>
            <div class="service-icon">
              <img
                class="icon center-block"
                src="images/icons/presentation1.png"
                alt=""
              />
            </div>
            <div class="service-text">
              <h4>
                <router-link to="/LearNing">สอนเขียนโปรแกรม</router-link>
                <!-- <a href="LearNing">สอนเขียนโปรแกรม</a> -->
              </h4>
              <p>
                รับสอนเขียนโปรแกรมคอมพิวเตอร์
                สำหรับนักเรียนที่ต้องการจะเรียนเขียนโปรแกรมคอมพิวเตอร์
                สอนตั้งแต่เริ่มต้น
              </p>
              <p></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- =======================================
    ==Start more About section==
    =======================================-->

    <section class="more-about-us" id="about">
      <div class="image-box">
        <svg
          version="1.1"
          class="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="854"
          height="632"
        >
          <clipPath class="clip1">
            <use xlink:href="#shape-one"></use>
          </clipPath>
          <g clip-path="url(#shape-one)">
            <image
              width="854"
              height="632"
              href="../../assets/images/de-software.png"
              class="image-shape img-responsive"
            ></image>
          </g>
        </svg>
      </div>
    </section>
    <!-- =======================================
    ==End more About section==
    =======================================-->
    <section class="more-about-us" id="about">
      <div class="image-box">
        <svg
          version="1.1"
          class="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="854"
          height="632"
        >
          <clipPath class="clip1">
            <use xlink:href="#shape-one"></use>
          </clipPath>
          <g clip-path="url(#shape-one)">
            <image
              width="854"
              height="632"
              href="../../assets/images/de-software.png"
              class="image-shape img-responsive"
            ></image>
          </g>
        </svg>
      </div>
      <div class="theme-shape-one"></div>
      <div class="container">
        <div class="row">
          <div class="col-lg-7 col-md-offset-5">
            <div class="main-content">
              <h2>สอนเขียนโปรแกรม</h2>
              <div class="main-wrapper">
                <h4>รับสอนเขียนโปรแกรมคอมพิวเตอร์</h4>
                <p>
                  สำหรับ นักเรียนที่ต้องการจะเรียนเขียนโปรแกรมคอมพิวเตอร์
                  สอนตั้งแต่เริ่มต้น สอนเขียนโปรแกรมบน Web สอน Web Programming
                  สอนJAVA สอนC# สอนC สอนC++ สอนVB.NET สอนPYTHON สอนPHP
                  สอนJAVASCRIPT สอนData Structure สอนAlgorithm
                  เรียนโดยการเน้นลงมือปฏิบัติ เรียนจบแล้วมีงานทำ เรียนจบแล้วเทพ
                  ถ้าทำการบ้านและโจทย์เยอะๆ สอนโดยการเน้นกระบวนการคิด และ
                  Algorithm
                </p>
                <div class="button-wrapper p-bg-color"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="call-to-action bg01" style="background: #eee9e9">
      <div class="container">
        <div class="row">
          <div class="title-box">
            <div class="col-md-3">
              <i
                class="fa fa-leanpub"
                aria-hidden="true"
                style="font-size: 80px; color: #ffcc00"
              ></i>
              <h3 class="article__title">เรียนรู้ตั้งแต่พื้นฐาน</h3>
              <p>
                เน้นการเรียนรู้ที่เป็นส่วนแก่นสำคัญเพื่อวางรากฐานแนวคิดที่สนุกที่สุด
              </p>
            </div>
            <div class="col-md-3">
              <i
                class="fa fa-flask"
                aria-hidden="true"
                style="font-size: 80px; color: #ffcc00"
              ></i>
              <h3 class="article__title">เน้นการปฏิบัติจริง</h3>
              <p>เพราะการลงมือทำคือการสร้างประสบการณ์เรียนรู้ที่ดีที่สุด</p>
            </div>

            <div class="col-md-3">
              <i
                class="fa fa-desktop"
                aria-hidden="true"
                style="font-size: 80px; color: #ffcc00"
              ></i>
              <h3 class="article__title">เรียนรู้จากงานจริง</h3>
              <p>เน้นการเรียนรู้จากงานจริง ที่บริษัทเคยสร้างและพัฒนา</p>
            </div>

            <div class="col-md-3">
              <i
                class="fa fa-heart"
                aria-hidden="true"
                style="font-size: 80px; color: #ffcc00"
              ></i>
              <h3 class="article__title">สนุกจากการเรียน</h3>
              <p>
                แปลงเนื้อหาที่สุดหิน ให้กลายเป็นสิ่งต่างๆ รอบตัว
                รับรองง่ายกว่าที่คิด !
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="round-built-by hidden-sm hidden-xs">
        <div class="round-animate">
          <div class="round-1">
            <span class="r-1"></span>
            <span class="r-2"></span>
          </div>
          <div class="round-2">
            <span class="r-1"></span>
            <span class="r-2"></span>
          </div>
          <div class="round-3">
            <span class="r-1"></span>
            <span class="r-2"></span>
          </div>
        </div>
      </div>
    </section>
    <div class="two-wrapper">
      <br /><br />
      <center>
        <img
          width="1000"
          height="400"
          src="../../assets/images/le-software2.png"
        />
      </center>
      <br /><br />
    </div>
    <FooTer></FooTer>
  </div>
</template>

<script>
export default {
  name: "LearNing",
};
</script>
