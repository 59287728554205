<template>
  <div id="feature">
    <section class="features" id="features">
      <div class="title-box">
        <h2 class="title-center" style="font-size: 35px">
          คุณสมบัติพิเศษของเรา
        </h2>
        <p class="description text-center" style="font-size: 17px">
          ออกแบบเว็บไซต์ให้สวยงาม ลงข้อมูลให้ครบ มีระบบหลังบ้าน ใช้งานง่ายดูแล
          อัพเดทแก้ไขเว็บไซต์ให้ฟรี 1 ปี ตามแพ็กเกจทำเว็บไซต์
        </p>
      </div>
      <div class="container">
        <div class="row">
          <div class="row items-grid">
            <div v-for="(feature, i) in features" :key="i" class="col-md-3">
              <div class="article box-shadow">
                <img class="center-block" :src="feature.icon" />
                <h3 class="article__title">{{ feature.name }}</h3>
              </div>
            </div>
            <!-- ****อันนี้เขียนแบบใช้ v-for ในนการเอาข้อมูลมาโชว์ ถ้าเรามีข้อมูลแค่ไหนก็เอามาโชว์แค่นั้นตามที่มีข้อมูล*** -->

            <!-- <div class="col-md-3">
              <div class="article box-shadow">
                <img class="center-block" src="../assets/images/Preple.png" />
                <h3 class="article__title">การจัดการผู้ใช้งาน</h3>
              </div>
            </div>

            <div class="col-md-3">
              <div class="article box-shadow">
                <img class="center-block" src="../assets/images/Boxx.png" />
                <h3 class="article__title">Addons</h3>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "FeaTures",
  data() {
    return {
      features: [
        { icon: "../assets/images/Capture.png", name: "มุมมองหน้าจอ" },
        { icon: "../assets/images/Box.png", name: "กาาชำระและแรียกเงิน" },
        { icon: "../assets/images/Preple.png", name: "การจัดการผู้ใช้งาน" },
        { icon: "../assets/images/Boxx.png", name: "Addons" },
        { icon: "../assets/images/Teeth.png", name: "API" },
        { icon: "../assets/images/Dorpper.png", name: "การออกแบบที่ดี" },
        { icon: "../assets/images/Key.png", name: "ความปลอดภัย" },
        { icon: "../assets/images/Wrench.png", name: "การจัดการหลังบ้าน" },
      ],
    };
  },
  created() {
    console.log("created", this.features);
  },
  beforeCreate() {
    console.log("beforeCreated", this.features);
  },
};
</script>

<style scoped>
.center-block {
  margin-left: auto;
  margin-right: auto;
}
</style>