<template>
  <diV id="footer">
    <section class="main-footer footer-style-two text-center" id="footer">
      <div class="widgets-section">
        <div class="container">
          <div class="row clearfix">
            <!--Footer Column-->
            <div class="footer-column col-md-4 col-sm-6 col-xs-12">
              <div class="footer-widget about-widget">
                <div class="has-line-center">
                  <a href="/#about" class="service-text" style="color: #ffffff"
                    >เกี่ยวกับเรา</a
                  >
                </div>
                <div class="widget-content">
                  <div class="text">
                    บริษัท ธันเดอร์ ซอฟต์แวร์ จำกัด รับเขียนโปรแกรมคอมพิวเตอร์
                    web application หลากหลายรูปแบบไม่ว่าจะป็นบริษัทหรือหน่วยงาน
                    รับปรึกษาปัญหาซอฟต์แวร์ ทั้งในและนอกบริษัท
                    รับสอนเขียนโปรแกรม ตั้งแต่พื้นฐาน
                    เรายินดีให้บริการด้วยความจริงใจ ไม่เอาเปรียบลูกค้า
                    เราหวังเป็นอย่างยิ่งว่าจะมีโอกาสได้ให้บริการลูกค้าทุกท่าน
                  </div>
                </div>
              </div>
            </div>
            <!--End Footer Column-->
            <!--Footer Column-->
            <div class="footer-column col-md-4 col-sm-6 col-xs-12">
              <div class="footer-widget contact-widget">
                <h3 class="has-line-center">ติดต่อเรา</h3>
                <div class="widget-content">
                  <ul class="contact-info">
                    <li>
                      <div class="icon">
                        <span class="ui-computer-screen-2"></span>
                      </div>
                    </li>
                    <li>998/86 ต.เมืองใต้ อ.เมือง จ.ศรีสะเกษ</li>
                    <li>โทร : 086-8555543</li>
                    <li>facebook.com/Thundersoftware</li>
                    <li>Line : @thundersoftware</li>
                    <li>Twitter : thundersoftware</li>
                  </ul>
                </div>
              </div>
            </div>
            <!--End Footer Column-->
            <div class="footer-column col-md-4 col-sm-12 col-xs-12">
              <div class="footer-widget">
                <h3 class="has-line-center">จดหมายข่าว</h3>
                <div class="widget-content">
                  <div class="text">
                    บริษัทธันเดอร์ ซอฟต์แวร์ จำกัด อัพเดตข่าวสารล่าสุด
                  </div>
                  <form>
                    <div class="input-group col-md-10">
                      <span class="input-group-text" id="addon-wrapping"
                        ><i class="fa fa-user"></i
                      ></span>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Name"
                        aria-label="Username"
                        required
                      />
                    </div>

                    <div class="input-group col-md-10 mt-2">
                      <span class="input-group-text" id="addon-wrapping"
                        ><i class="fa fa-envelope"></i
                      ></span>
                      <input
                        type="email"
                        id="email"
                        class="form-control"
                        placeholder="Email"
                        aria-label="Username"
                      />
                    </div>

                    <div
                      class="input-group"
                      style="
                        height: 50px;
                        width: 30px !importent;
                        padding-left: 30%;
                        padding-top: 5px;
                      "
                    >
                      <button
                        type="btn"
                        class="btn btn-secondary"
                        style="padding-right: 50px; padding-left: 50px"
                      >
                        ส่ง
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <a
      class="btn-lg scrollup"
      style="display: block; border-radius: 100px"
      @click="toTop"
      ><i class="fa fa-arrow-up"></i
    ></a>
    <div class="copyright">
      <h4 class="text-center">Thunder Software Co.,Ltd.</h4>
    </div>
  </diV>
</template>
<script>
export default {
  name: "FooTer",
  data() {
    return {
      scTimer: 0,
      scY: 0,
    };
  },
  methods: {
    toTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    // handleScroll() {
    //   if (this.scTimer) return;
    //   this.scTimer = setTimeout(() => {
    //     this.scY = window.scrollTo;
    //     clearTimeout(this.scTimer);
    //     this.scTimer = 0;
    //   }, 100);
    // },
  },
};
</script>




<style scoped>
p.margin-5 {
  margin-top: 25px;
}
</style>

