<template>
  <div>
    <section id="signup-section" class="signup-section section">
      <div class="section-inner">
        <div class="container text-center">
          <div class="title-box">
            <h2 class="title-center">ติดต่อสอบถาม</h2>
            <p class="description text-center">
              มีเรื่องสงสัย ติดต่อสอบถามเจ้าหน้าที่ได้เลยค่ะ
            </p>
          </div>
          <div class="form-wrapper">
            <h2 class="form-title">กรุณากรอกข้อมูลเพื่อสอบถาม</h2>
            <div class="form-box">
              <form
                class="question-form"
                method="POST"
                action="/customer-question"
              >
                <div class="row">
                  <div class="form-group col-12 col-md-6">
                    <label for="name" class="sr-only">ชื่อ</label>
                    <input
                      type="text"
                      class="form-control"
                      id="name"
                      name="name"
                      placeholder="ชื่อ"
                    />
                  </div>
                  <!--//form-group-->
                  <div class="form-group col-12 col-md-6">
                    <label for="email" class="sr-only">อีเมลล์</label>
                    <input
                      type="email"
                      class="form-control"
                      id="email"
                      name="email"
                      placeholder="อีเมลล์"
                    />
                  </div>
                  <!--//form-group-->
                  <div class="form-group col-12 col-md-12">
                    <label for="phone" class="sr-only">เบอร์โทร</label>
                    <input
                      type="text"
                      class="form-control"
                      id="phone"
                      name="phone"
                      placeholder="เบอร์โทร"
                    />
                  </div>
                  <!--//form-group-->
                  <div class="form-group col-12 col-md-12">
                    <label for="question" class="sr-only">คำถาม</label>
                    <textarea
                      type="text"
                      class="form-control"
                      id="question"
                      name="question"
                      placeholder="คำถาม"
                    ></textarea>
                  </div>
                  <!--//form-group-->
                </div>
                <!--//row-->

                <button type="type='submit'" class="btn btn-primary">
                  ส่งข้อมูลสอบถาม
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "QueryInfo",
};
</script>
<style scoped>
</style>
