<template>
  <div>
    <br />
    <p align="center" style="font-size: 20px">บริการทั้งหมดของเรา</p>
    <br />
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-18 col-sm-36">
          <div class="single-service-item">
            <span class="top-border"></span>
            <span class="right-border"></span>
            <span class="bottom-border"></span>

            <div class="service-left-bg"></div>
            <div class="service-icon">
              <img
                class="icon center-block"
                src="../../assets/images/icons/settings.png"
                alt=""
              />
            </div>
            <div class="service-text">
              <h4>
                <router-link to="/consult">ปรึกษาปัญหาซอฟต์แวร์</router-link>
                <!-- <a href="consult">ปรึกษาปัญหาซอฟต์แวร์</a> -->
              </h4>
              <p>
                บริการปัญหาและดูแลเว็บไซต์ อัพเดท ปรับปรุงเว็บไซต์
                ทั้งเว็บไซต์ที่ทำมาจากที่อื่นและเว็บไซ์ที่ทำกับเรา
              </p>
              <p></p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="more-about-us" id="about">
      <div class="image-box">
        <svg
          version="1.1"
          class="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="854"
          height="632"
        >
          <clipPath class="clip1">
            <use xlink:href="#shape-one"></use>
          </clipPath>
          <g clip-path="url(#shape-one)">
            <image
              width="854"
              height="632"
              href="../../assets/images/consult2.png"
              class="image-shape img-responsive"
            ></image>
          </g>
        </svg>
      </div>
      <div class="theme-shape-one"></div>
      <div class="container">
        <div class="row">
          <div class="col-lg-7 col-md-offset-5">
            <div class="main-content">
              <h2>ให้คำปรึกษาปัญหาซอฟต์แวร์</h2>
              <div class="main-wrapper">
                <h4>
                  แก้ปัญหา ให้คำปรึกษา การใช้งานระบบคอมพิวเตอร์
                  และโปรแกรมสำเร็จรูปต่างๆ
                </h4>
                <p>
                  1. ศึกษาระบบงานคอมพิวเตอร์ การใช้งานโปรแกรมสำเร็จรูป
                  และความต้องการของลูกค้า <br />2. นำเสนอแผนงานพร้อมเอกสาร
                  เพื่อหาข้อสรุปการดูแลระบบงานคอมพิวเตอร์ <br />3.
                  เซ็นต์สัญญาจ้างงานและส่งแผนงานการดำเนินการ <br />4.
                  ให้คำปรึกษาปัญหาการใช้งาน
                  และนำเสนอแผนปรับปรุงระบบงานให้สอดคล้อมกับความต้องการของลูกค้า
                </p>
                <div class="button-wrapper p-bg-color"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="two-wrapper" style="margin: auto">
      <section class="no-padding-top">
        <div class="row">
          <div
            class="
              services-column
              col-lg-5 col-lg-offset-1 col-md-10 col-md-offset-1 col-xs-12
            "
            style="margin: auto; align=center;"
          >
            <h2>บริการด้านการปรึกษาปัญหา Software</h2>
            <div style="text-indent: 2.5em">
              <p>- บริการให้คำปรึกษาด้านไอที การออกแบบ และ Software</p>
              <p>- ให้คำปรึกษาในออกแบบเว็บไซต์ให้ตรงกับธุรกิจของลูกค้า</p>
              <p>- ให้คำปรึกษาในการปรับปรุงพัฒนาระบบ จากที่อื่นและที่บริษัท</p>
              <p>
                - ให้คำปรึกษาและจัดหาเกี่ยวกับระบบสำรองข้อมูล Backup Solution
              </p>
              <p>- แนะนำการและจัดหา Software ที่เหมาะสมกับการใช้งาน</p>
              <p></p>
              <p>
                - แนะนำการและจัดหา Software ที่ใช้ในธุรกิจ (ERP, CRM, CMMS,
                Ticketing Software)
              </p>
              <p></p>
            </div>
          </div>

          <div class="service-img-wrapper col-lg-6 col-md-12 col-sm-12">
            <img
              width="600"
              height="500"
              src="../../assets/images/consult1.png"
              class="img-responsive"
              alt=""
            />
          </div>
        </div>
      </section>
    </div>
    <FooTer></FooTer>
  </div>
</template>
<script>
export default {
  name: "ConSult",
};
</script>
<style scoped>
.center-block {
  margin-left: auto;
  margin-right: auto;
}
</style>
