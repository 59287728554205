<template>
  <div id="about">
    <section class="more-about-us">
      <div class="image-box">
        <svg
          version="1.1"
          class="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="854"
          height="632"
        >
          <clipPath class="clip1">
            <use xlink:href="#shape-one"></use>
          </clipPath>
          <g clip-path="url(#shape-one)">
            <image
              width="854"
              height="632"
              href="../assets/images/laptopp1.png"
              class="image-shape img-responsive"
            ></image>
          </g>
        </svg>
      </div>
      <div class="theme-shape-one"></div>
      <div class="container">
        <div class="row">
          <div class="col-lg-7 col-md-offset-5">
            <div class="main-content">
              <h2>ออกแบบเว็บไซต์</h2>
              <div class="main-wrapper">
                <h4>ประโยชน์การออกเว็บไซต์</h4>

                <p>
                  การออกแบบเว็บไซต์ เพื่อแก้ปัญหา ลดเวลาทำงาน เพิ่มประสิทธิภาพ
                  และช่วยเพิ่มโอกาสที่ดีให้ธุรกิจคุณ
                  อำนวยความสะดวกให้กับธุรกิจทุกรูปแบบไม่ว่าจะเป็นบริษัท หน่วยงาน
                  องค์กร หรือบุคคลทั่วไป
                  ให้ตรงกับความต้องการของลูกค้าอย่างมีประสิทธิภาพสูงสุด
                </p>
                <div class="button-wrapper p-bg-color"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- =======================================
    ==End more About section==
    =======================================-->

    <!-- =======================================
    ==Start Call to action section==
    =======================================-->
    <section class="call-to-action bg01 bg-img">
      <div class="container">
        <div class="row">
          <div
            class="col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12"
          >
            <div class="title-box">
              <h2 class="title-center white" style="color: #333333 !important">
                บริษัท ธันเดอร์ ซอฟต์แวร์ จำกัด
              </h2>

              <p
                class="description text-center white"
                style="font-size: 15px; color: #444444 !important"
              >
                บริษัทธันเดอร์ ซอฟต์แวร์ จำกัด พัฒนาเว็บไซต์ ด้วยระบบ CMS
                ที่ใช้งานง่ายที่สุด รองรับและตอบสนอง ต่อการใช้งานของลูกค้า
                อำนวยความสะดวก ให้กับการใช้งานทุกอย่าง บนธุรกิจออนไลน์
                มาพร้อมครื่องมือที่ ที่เรารวบรวมไว้ ใน CMS หลังบ้านของคุณ
              </p>
            </div>
            <router-link to="/QueryInfo" class="btn-login"
              >สอบถามข้อมูล</router-link
            >
          </div>
        </div>
      </div>
      <div class="round-built-by hidden-sm hidden-xs">
        <div class="round-animate">
          <div class="round-1">
            <span class="r-1"></span>
            <span class="r-2"></span>
          </div>
          <div class="round-2">
            <span class="r-1"></span>
            <span class="r-2"></span>
          </div>
          <div class="round-3">
            <span class="r-1"></span>
            <span class="r-2"></span>
          </div>
        </div>
      </div>
    </section>

    <!-- ==End Call to action section== -->

    <div class="two-wrapper">
      <section class="video video-1">
        <div class="title-box">
          <h2 class="title-center">คุณสมบัติที่ดีที่สุดกว่าที่เคย</h2>
          <p class="description text-center" style="font-size: 17px">
            รองรับทุกอุปกรณ์, ฟังค์ชั่นการใช้งานครบ, รูปแบบเว็บไซต์สวยทันสมัย
          </p>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 module come-in">
              <div class="mac">
                <img
                  src="../assets/images/video-device1.png"
                  class="img-responsive center-block"
                  alt="mac"
                />

                <a
                  class="video-play text-theme-color"
                  title="Video Presentation"
                  href="#"
                >
                  <i class="fa fa-play"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- ==End Video section== -->

      <!-- ==Start Services section== -->

      <section>
        <div class="container-fluid">
          <div class="row">
            <div
              class="
                services-column
                col-lg-5 col-lg-offset-1 col-md-10 col-md-offset-1 col-xs-12
              "
            >
              <!--Services Block Four-->
              <div class="services-block-four">
                <div class="inner-box">
                  <div class="icon-box">
                    <span class="fa fa-telegram"></span>
                  </div>
                  <h3><a href="services-single.html">รองรับทุกอุปกรณ์</a></h3>
                  <div class="text">
                    รองรับทุก Device การใช้งาน ของเว็บไซต์ที่เคยพบมา
                    ด้วยการแสดงผลมีเอกลักษณ์ ไร้ขีดจำกัดของ Responsive Designs
                    ให้ธุรกิจก้าวล้ำนำหน้าคู่แข่ง
                  </div>
                </div>
              </div>

              <!--Services Block Four-->
              <div class="services-block-four">
                <div class="inner-box">
                  <div class="icon-box">
                    <span class="fa fa-cogs"></span>
                  </div>
                  <h3><a href="services-single.html">ฟังค์ชั่นการใช้งาน</a></h3>
                  <div class="text">
                    ฟังก์ชั่นรองรับการใช้งาน
                    ที่พัฒนาขึ้นเฉพาะกับธุรกิจของคุณมากที่สุด
                    เพื่อช่วยเพิ่มโอกาสที่ดีกว่าให้ธุรกิจ
                  </div>
                </div>
              </div>

              <!--Services Block Four-->
              <div class="services-block-four">
                <div class="inner-box">
                  <div class="icon-box">
                    <span class="fa fa-heart-o"></span>
                  </div>
                  <h3><a href="services-single.html">รูปแบบเว็บไซต์</a></h3>
                  <div class="text">
                    เพื่อให้ลูกค้าได้รับเว็บไซต์
                    ที่สร้างความน่าเชื่อถือได้มากที่สุด
                    และภาพลักษณ์ที่ดีที่สุดให้กับธุรกิจ
                  </div>
                </div>
              </div>
            </div>
            <div class="service-img-wrapper col-lg-6 col-md-12 col-sm-12">
              <div class="image-box">
                <img
                  src="../assets/images/webday-coding.png"
                  class="img-responsive"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- ==End Services section== -->
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUs",
};
</script>

<style scoped>
.bg-img {
  background-image: url("../assets/images/bg2.jpg");
}
.section {
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;
  font-size: 50px;
}
</style>
