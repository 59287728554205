import Vue from 'vue'
import Vuex from 'vuex'

import getnav from "./modules/getnav";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    getnav
  }
})




