const state = {
    tabmenu: [
        "hello"
    ]
}

const getters = {
    getTabMenu(state){
        return state.tabmenu
    }
}

const mutations = {
    setTabMenu(state, item) {
        state.tabmenu = item
    },
}
const actions = {
    SetLoadingStatus({ commit }, data) {
        commit('setStatusLoading', data);
    },
    SetLoadingStatus_lang({ commit }, data) {
        commit('setStatusLoading_lang', data);
    },
    SetLoadingStatus_walletglobal({ commit }, data) {
        commit('setStatusLoading_walletglobal', data);
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}