<template>
  <div>
    <br />
    <p align="center" style="font-size: 20px">บริการทั้งหมดของเรา</p>
    <br />
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-4 col-sm-12">
          <div class="single-service-item">
            <span class="top-border"></span>
            <span class="right-border"></span>
            <span class="bottom-border"></span>

            <div class="service-left-bg"></div>
            <div class="service-icon">
              <img
                class="icon center-block"
                src="../assets/images/icons/website.png"
                alt=""
              />
            </div>
            <div class="service-text">
              <h4>
                <router-link :to="{ path: '/WriteProgram' }"
                  >เขียนโปรแกรม /Web application</router-link
                >
                <!-- <a href="WriteProgram">เขียนโปรแกรม /Web application</a> -->
              </h4>
              <p>
                รับทำเว็บไซต์ ออกแบบทันสมัย แสดงผลผ่านมือถือได้
                ลูกค้าสามารถอัพเดทเว็บไซต์ได้เอง
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="single-service-item">
            <span class="top-border"></span>
            <span class="right-border"></span>
            <span class="bottom-border"></span>

            <div class="service-left-bg"></div>
            <div class="service-icon">
              <img
                class="icon center-block"
                src="../assets/images/icons/settings.png"
                alt=""
              />
            </div>
            <div class="service-text">
              <h4>
                <router-link :to="{ path: '/consult' }"
                  >ปรึกษาปัญหาซอฟต์แวร์</router-link
                >
                <!-- <a href="consult">ปรึกษาปัญหาซอฟต์แวร์</a> -->
              </h4>
              <p>
                บริการปัญหาและดูแลเว็บไซต์ อัพเดท ปรับปรุงเว็บไซต์
                ทั้งเว็บไซต์ที่ทำมาจากที่อื่นและเว็บไซ์ที่ทำกับเรา
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="single-service-item">
            <span class="top-border"></span>
            <span class="right-border"></span>
            <span class="bottom-border"></span>

            <div class="service-left-bg"></div>
            <div class="service-icon">
              <img
                class="icon center-block"
                src="../assets/images/icons/presentation1.png"
                alt=""
              />
            </div>
            <div class="service-text">
              <h4>
                <router-link :to="{ path: '/Learning' }"
                  >สอนเขียนโปรแกรม</router-link
                >
                <!-- <a href="Learning">สอนเขียนโปรแกรม</a> -->
              </h4>
              <p>
                รับสอนเขียนโปรแกรมคอมพิวเตอร์
                สำหรับนักเรียนที่ต้องการจะเรียนเขียนโปรแกรมคอมพิวเตอร์
                สอนตั้งแต่เริ่มต้น
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SerVice",
};
</script>
<style scoped>
.nav-base {
  padding: 15px 0;
  top: 0;
  transition: all 200ms ease-in-out;
  z-index: 999;
}
.main-header.demo1 .main-header-sub {
  padding-top: 150px;
}
.center-block {
  margin-left: auto;
  margin-right: auto;
}
</style>
