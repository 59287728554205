<template>
  <div class="style-bg-full">
    <div class="container">
      <div class="login"><b>Admin</b><a href="/">ThunderSoftware</a></div>
      <diV class="card-sy">
        <form class="form-group has-feedback">
          <div class="input-group">
            <input
              v-model="emailaddrees"
              class="form-control"
              type="text"
              placeholder="E-mail Addrees"
              style="background-color: aliceblue; border-right: 0px"
              required
            />
            <span
              class="input-group-text"
              style="background: aliceblue; border-left: 0px"
              ><i class="fa fa-envelope" style="color: #777"></i
            ></span>
          </div>

          <br />

          <div class="input-group">
            <input
              v-model="emailaddrees"
              class="form-control"
              type="password"
              placeholder="Password"
              style="background-color: aliceblue; border-right: 0px"
              required
            />
            <span
              class="input-group-text"
              style="background: aliceblue; border-left: 0px"
              ><i class="fa fa-lock" style="color: #777"></i>
            </span>
          </div>

          <br />
          <div class="form-group row">
            <div class="col-md-6">
              <div class="checkbox" style="margin-left: 20px">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckChecked"
                />

                <label for="flexCheckChecked" style="color: #8c95a9">
                  Remember
                </label>
              </div>
            </div>
            <button class="text-button" @click="login()">Sing In</button>
          </div>
        </form>
      </diV>
    </div>
  </div>
</template>
<script>
export default {
  name: "AdminLogin",
  data() {
    return {
      email: null,
      password: null,
    };
  },
};
</script>

<style scoped>
.style-bg-full {
  background: #d2d6de;
  height: 100vh;
  padding: 150px;
}
.card-sy {
  padding: 20px;
  background-color: #fff;
  width: 360px;
  margin: 0% auto;
}

.text-button {
  color: #fff;
  margin-left: 77%;
  width: auto;
  margin-top: -35px;
  background: #367fa9;
}
.login {
  font-size: 35px;
  text-align: center;
  margin-bottom: 25px;
  font-weight: 300;
}
</style>
