<template>
  <div>
    <Service></Service>
    <About-Us></About-Us>
    <Fea-Tures></Fea-Tures>
  </div>
</template>

<script>
import AboutUs from "../components/AboutUs.vue";
import FeaTures from "../components/FeaTures.vue";
import Service from "../components/Service.vue";

export default {
  name: "MainContent",
  components: {
    AboutUs,
    FeaTures,
    Service,
  },
};
</script>



<style scoped>
</style>
