 <template>
  <div>
    <header data-spy="scroll" data-target=".navbar">
      <nav
        class="navbar navbar-expand-md navbar-dark fixed-top nav-base dark"
        :class="view.topOfPage ? 'mini-nav' : ''"
        id="minify_nav"
      >
        <!-- mini-nav  -->
        <div class="container">
          <a class="navbar-brand" href="/">
            <img
              class="toggle-logo logo-white"
              src="../assets/images/logot.png"
              alt=""
            />
            <img
              class="toggle-logo logo-blue"
              src="../assets/images/logoth.png"
              alt=""
            />
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navigation"
            aria-controls="navigation"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i class="fa fa-bars"></i>
          </button>
          <div class="collapse navbar-collapse" id="navigation">
            <ul class="navbar-nav ml-auto">
              <li class="nav-item">
                <a type="button" class="nav-link" href="/#"
                  >หน้าแรก <span class="sr-only"></span
                ></a>
              </li>
              <li class="nav-item">
                <a type="button" class="nav-link" href="/#about"
                  >เกี่ยวกับเรา<span class="sr-only"></span
                ></a>
              </li>
              <li class="nav-item">
                <a type="button" class="nav-link" href="/#feature"
                  >คุณสมบัติ<span class="sr-only"></span
                ></a>
              </li>
              <li class="nav-item">
                <a type="button" class="nav-link" href="/#footer"
                  >ติดต่อเรา<span class="sr-only"></span
                ></a>
              </li>
              <li class="nav-item">
                <router-link to="/Question">Q&A</router-link>
                <!-- <a type="button" class="nav-link"
                  >Q&A<span class="sr-only"></span
                ></a> -->
              </li>
              <li class="nav-item">
                <a class="badge-success" v-b-modal.modal-center-applywork
                  >สมัครงาน<span class="sr-only"></span
                ></a>
              </li>
              <li class="nav-item">
                <a
                  type="button"
                  class="nav-link"
                  href="http://bo.thundersoftware.co.th/admin/login"
                  >เข้าสู่ระบบ<span class="sr-only"></span
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <section class="main-header demo1" style="overflow: hidden" id="main">
        <div class="home-cont">
          <div class="container">
            <div class="row">
              <div class="main-header-sub text-left">
                <div class="col-md-5">
                  <div class="content-text">
                    <h1>ธันเดอร์ ซอฟต์แวร์</h1>
                    <h3>
                      รับปรึกษาปัญหา และเขียนโปรแกรม เพื่อแก้ปัญหาให้ลูกค้า
                      ลดเวลาทำงาน เพิ่มประสิทธิภาพ
                    </h3>
                  </div>
                </div>
                <div class="col-md-7">
                  <div class="img-section">
                    <img
                      class="img-browser img-fluid"
                      src="../assets/images/logoh.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="round-header-circle hidden-sm hidden-xs">
          <div class="round-animate">
            <div class="round-1">
              <span class="r-1"></span>
              <span class="r-2"></span>
              <span class="r-3"></span>
              <span class="r-4"></span>
            </div>
            <div class="round-2">
              <span class="r-1"></span>
              <span class="r-2"></span>
              <span class="r-3"></span>
              <span class="r-4"></span>
            </div>
            <div class="round-3">
              <span class="r-1"></span>
              <span class="r-2"></span>
              <span class="r-3"></span>
              <span class="r-4"></span>
            </div>
          </div>
        </div>
      </section>
    </header>
    <h5 class="p-3 bg-dark text-white text-center">
      Thunder Software Co.,Ltd. ศูนย์รับปรึกษาปัญหา และเขียนโปรแกรม
    </h5>
  </div>
</template>

<script>
export default {
  name: "HeaderMain",
  // components: {
  //   "v-scroll": vuescroll, // or just vuescroll tried both
  // },
  data() {
    return {
      view: {
        topOfPage: false,
      },

      scTimer: 0,
      scY: 0,
    };
  },
  computed: {
    // ...mapState({
    //   tabmenu: (state) => state.tabmenu,
    // }),
    // ...mapGetters({
    //   getTabMenu: "getnav/getTabMenu",
    // }),
  },
  // mounted() {
  //   window.addEventListener("scroll", this.handleScroll());
  // },
  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      // console.log("handleScroll", window.pageYOffset, window.pageYOffset);
      if (window.pageYOffset > 50) {
        this.view.topOfPage = true;
      } else {
        this.view.topOfPage = false;
      }
      // if (this.scTimer) return;
      // this.scTimer = setTimeout(() => {
      //   this.scY = window.scrollY;
      //   clearTimeout(this.scTimer);
      //   this.scTimer = 0;
      // }, 100);
    },
    // ...mapActions("getnav", ["ClickTab"]),
    // home() {
    //   this.$router.push({ path: "/" });
    // },
    // aboutUs() {
    // this.$router.push({ path: "/AboutUs" });
    // this.$store.commit("setTabMenu", "AboutUs");
    // console.log("ffffff");
    // },
    // feaTures() {
    //   this.$router.push({ path: "/FeaTures" });
    // },
    // footer() {
    //   this.$router.push({ path: "/Footer" });
    // },
    // question() {
    //   this.$router.push({ path: "/Question" });
    // },
    // admin() {
    //   this.$router.push({ path: "/login" });
    // },
  },
  // watch: {
  //   scY(value) {
  //     console.log(">>>>>>>>>>>>>", value);
  //   },
  // },
};
</script>
<style scoped>
/* .nav-base.dark.mini-nav #navigation ul li a.badge-success {
  color: #fff;
} */
</style>