<template>
  <b-modal id="modal-center-applywork" size="lg" hide-footer centered>
    <template #modal-title
      ><i
        class="fa fa-file-text-o"
        style="font-size: 50px; margin-left: 20px"
      ></i>
      <span style="font-size: 35px; margin-left: 10px">แบบฟอร์มสมัครงาน</span>
    </template>
    <div class="modal-body">
      <div class="mm">
        <h5>
          <i class="fa fa-desktop"></i> ตำแหน่งงาน
          <label style="color: red">*</label>
        </h5>
        <div>
          <b-form-select
            class="form-control form-control-md"
            v-model="selected"
            :options="makePosions"
          >
          </b-form-select>
        </div>
        <div style="padding-right: 5px; padding-left: 5px; margin-left: -15px">
          <b-container class="bv-example-row">
            <b-row>
              <b-col cols="6" md="6">
                <h5>
                  <i class="fa fa-envelope"></i> อีเมล์
                  <label style="color: red">*</label>
                </h5>

                <b-form-input
                  id="input-live"
                  type="email"
                  v-model="email"
                  :state="emailState"
                  placeholder="ตัวอย่าง: thundersoftware@gmail.com"
                  trim
                ></b-form-input>

                <b-form-invalid-feedback v-show="emailState === false">
                  {{ emailStatetext }}
                </b-form-invalid-feedback>
              </b-col>
              <b-col cols="6" md="6">
                <h5>
                  <i class="fa fa-phone"></i> เบอร์โทรศัพท์
                  <label style="color: red">*</label>
                </h5>
                <b-form-input
                  type="number"
                  v-model="phone"
                  :state="phoneState"
                  placeholder="ตัวอย่าง: 0812345678"
                  trim
                ></b-form-input>
                <b-form-invalid-feedback
                  id="input-live-feedback"
                  v-show="phoneState === false"
                >
                  {{ phoneStatetext }}
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </div>
    </div>
    <div class="detail">
      <h5>
        <i class="fa fa-list-alt"></i> รายละเอียดของผู้สมัคร
        <label style="color: red">*</label>
      </h5>
      <!-- :state="detailValidate" -->
      <b-form-textarea
        rows="10"
        v-model="form_detail"
        :state="formdetail"
        placeholder="แนะนำตัวของผู้สมัครกับทางบริษัทโดยไม่ต้องมีข้อความยาวมาก"
        trim
      ></b-form-textarea>
      <b-form-invalid-feedback id="input-live-feedback">
        กรุณากรอกข้อมูลในช่องนี้</b-form-invalid-feedback
      >
    </div>
    <div style="padding-left: 20px">
      <h5>
        <i class="fa fa-file-pdf-o" style="font-size: 25px"></i
        ><span style="font-size: 15px; margin-left: 5px"
          >Resume หรือเอกสารประวัติส่วนตัว (สกุลของไฟล์ต้องเป็น .pdf
          เท่านั้น)</span
        >
      </h5>
      <b-form-file
        v-model="files"
        ref="file-input"
        accept=".pdf"
        class="mt-3"
        plain
      ></b-form-file>
    </div>

    <div class="modal-footer">
      <b-button
        variant="success"
        style="background-color: green"
        @click="applywork()"
        >ยืนยันการสมัครงาน</b-button
      >
      <b-button
        @click="$bvModal.hide('modal-center-applywork')"
        style="background-color: #b4b4b4"
      >
        ปิด
      </b-button>
    </div>
  </b-modal>
</template>
<script>
import axios from "axios";
export default {
  name: "ApplyWork",
  data() {
    return {
      URL: "https://bo.thundersoftware.co.th/api/position", // ข้อมูลที่เราสามารถmeake ขึ้นมาได้
      name: "",
      email: "",
      emailRe:
        /^(([^<>()[]\\.,;:s@"]+(.[^<>()[]\\.,;:s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$/,

      phone: "",
      selected: null,
      positon: [],
      form_detail: "",
      files: null,
      // article: {
      //   job_id: null,
      //   form_detail: null,
      //   form_email: null,
      //   form_phone: null,
      // },
    };
  },
  computed: {
    article() {
      return {
        job_id: this.selected,
        form_detail: this.form_detail,
        form_email: this.email,
        form_phone: this.phone,
      };
    },
    emailState() {
      var reg = new RegExp(
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      );
      return reg.test(this.email);
    },
    emailStatetext() {
      if (this.email.trim() == "") {
        return "กรุณากรอกข้อมูลในช่องนี้";
      } else {
        return "กรอกE-mail ไม่ถูกต้อง";
      }
    },
    phoneState() {
      return this.phone.length > 8 ? true : false;
    },
    phoneStatetext() {
      if (this.phone.trim() === "") {
        return "กรุณากรอกข้อมูลในช่องนี้";
      } else {
        return "หมายเลขโทรศัพท์ไม่ถูกต้อง";
      }
    },
    formdetail() {
      return this.form_detail.length > 8 ? true : false;
    },
    formdetailtext() {
      if (this.form_detail.trim() === "") {
        return "กรุณากรอกข้อมูลในช่องนี้";
      } else {
        return "หมายเลขโทรศัพท์ไม่ถูกต้อง";
      }
    },

    makePosions() {
      const map1 = this.positon.map((x) => {
        return {
          value: x.id,
          text: x.job_name,
        };
      });
      map1.push({ value: null, text: "---กรุณาเลือกตำแหน่ง---" });
      map1.sort(function (a, b) {
        return a.value - b.value;
      });

      return map1;
    },
  },

  methods: {
    // ฟังก์ชันที่เราจะให้มันกระทำอย่างไร
    getPositon() {
      axios.get(this.URL).then((resp) => {
        this.positon = resp.data.datas;
        console.log(resp);
      });
    },

    showAlert(value) {
      this.$swal({
        icon: "error",
        title: "Oops...",
        text: value,
      });
    },
    AlertSuccess(value) {
      this.$swal({
        icon: "success",
        title: "Good job!",
        text: value,
      });
    },

    applywork() {
      // this.showAlert();
      console.log("Applywork", this.article);
      // const article = { job_id: null };
      axios
        .post(
          "https://bo.thundersoftware.co.th/api/user_apply_job",
          this.article
        )
        .then((resp) => {
          console.log("Applywork", resp);
          if (resp.data.status != 0) {
            // alert(resp.data.message);
            this.showAlert(resp.data.message);
          } else if (resp.data.status == 0) {
            return this.AlertSuccess(resp.data.message);
          }
          resp;
        });
    },
  },

  created() {
    this.getPositon();
    console.log(this.URL);
  },
};
</script>
<style scoped>
.mm {
  padding: 20px;
}
.detail {
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 15px;
  margin: -45px 0px 0px 0px;
}
body {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}
</style>
